.LitWrapper {
	display: flex;
	height: 70vh;
	justify-content: center;
}

.Literature {
	width: 40%;
	margin: 100px;
}

.Link {
	color: var(--n_gray);
	text-decoration: none;
	font-weight: 400;
}

.Link:hover {
	color: var(--n_purple);
}

.words{
	font-size: 1.5em;
	color: black;
}
