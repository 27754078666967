.App {
	width: 100vw;
	height: 100vh;
	min-height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.App:after {
	content: "";
	display: block;
}

.footer,
.App:after {
	height: 1px;
}
