.Footer {
	position: fixed;
	bottom: 0;
	background: rgb(46, 31, 59);
	height: 125px;
	width: 100vw;
	color: white;
	/* margin-top: 50px; */
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: rgb(245, 245, 245);
}

.CR {
	margin: 25px;
	color: rgb(245, 245, 245);
}

.FooterCol {
	font-size: 0.8em;
}

.ContactForm {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 5px;
}

.ContactLink {
	font-weight: 300;
	font-size: 0.8;
	text-decoration: none;
	font-weight: 400;
	color: rgb(245, 245, 245);
	margin: 5px 5px 5px 5px;
}

@media (max-width: 500px) {
	.Footer {
		font-size: 0.7em;
	}
}
