.Body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 100px;
	animation: fadeIn 0.5s ease-in-out;
}

.AboutHR {
	margin-top: 50px;
	color: var(--n_gray);
	width: 100%;
}

h1 {
	color: var(--dark-gray);
}

.Content {
	width: 40%;
	max-width: 800px;
	min-width: 300px;
	color: var(--n_gray);
}

.GitLink {
	margin-bottom: 30px;
	border: none;
	outline: none;
	border-radius: 3px;
	font-size: 1.3em;
	padding: 5px;
	width: 150px;
	background-color: #238636;
	color: white;
	box-shadow: 0 4px 12px rgb(0 0 0 / 8%), 0 0 1px rgb(1 0 0 / 10%);

	transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.GitLink:hover {
	cursor: pointer;
	transform: translateY(-3px);
	background: #2ea043;
	box-shadow: 0 4px 24px rgb(0 0 0 / 16%), 0 0 1px rgb(1 0 0 / 16%);
}

.CR {
	font-size: 0.8em;
	color: var(--n_gray);
	font-weight: 300;
	letter-spacing: 0.8;
}

@keyframes fadeIn {
	0% {
		opacity: 0%;
		transform: translateY(300px);
	}

	100% {
		opacity: 100%;
		transform: translateY(0px);
	}
}
