.APIWrapper {
	display: flex;
	margin-top: 100px;
	color: var(--n_gray);
	min-height: 100%;
	flex-direction: column;
}

.API {
	width: 80%;
	margin-left: 35px;
}

.code {
	font-size: 16px;
	color: rgb(32, 10, 32);
	font-weight: 500;
	background-color: rgb(243, 243, 243);
	border-radius: 5px;
	margin: 5px;
	padding: 5px 0px 5px 5px;
	display: flex;
	flex-direction: row;
}

.HR {
	height: 3px;
	background-color: var(--n_purple);
	border: none;
	width: 5%;
	margin: 10px 0 0;
}

.Header {
	color: var(--dark-gray);
	display: flex;
	flex-direction: column;
	align-items: left;
	font-size: 18px;
}

.Header a {
	text-decoration: none;
	color: var(--dark-gray);
}

.Header a:hover {
	color: var(--n_purple);
}

.EP {
	color: var(--dark-gray);
}

.Name {
	font-weight: bold;
	margin-bottom: 10px;
	margin-top: 20px;
}

.EndPoint {
	color: var(--dark-gray);
	margin-bottom: 8px;
	width: 100%;
	display: flex;
}

.Body {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.SideNav {
	left: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100%;
	width: 250px;
	min-width: 250px;
	color: #666666;
	background-color: white;
}

.Outter {
	list-style: none;
	font-weight: 700;
}

.NestedNav {
	padding-left: 35px;
	padding-top: 12px;
	list-style: none;
}

.Nav {
	color: #666666;
	text-decoration: none;
}

.Nav:hover {
	color: var(--n_purple);
	transform: translateY(20px);
}

.Description {
	font-weight: 700;
	color: var(--dark-gray);
	display: flex;
	width: 400px;

}

.InnerNav {
	margin-bottom: 20px;
}

.Blockchain {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	align-items: flex-start;
	color: var(--dark-gray);
	font-weight: 700;
}

.Chevron {
	margin-top: 2px;
	margin-left: 5px;
	width: 18px;
	transition: all 200ms ease-in-out;
}
.Chevron90 {
	transform: rotate(-90deg);
	margin-left: 5px;
	width: 18px;
	transition: all 200ms ease-in-out;
}

.Example{
	width: 100%
}