.Home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 90vh;
	width: 100vw;
	animation: fadeIn 0.5s ease-in-out;
}
.Content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	line-height: 1.4;
	width: 60%;
}

.Text {
	width: 40vw;
	max-width: 700px;
	margin-right: 50px;
	padding: 30px;
	border-radius: 5px;
}

.Co {
	margin-top: 0px;
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 36px;
	text-transform: uppercase;
	color: var(--dark-gray);
}

.Welcome {
	font-weight: 400;
	font-size: 1.5em;
	text-transform: uppercase;
	margin-bottom: 0px;
	color: var(--n_gray);
}

.Sub {
	display: flex;
	color: var(--n_gray);
	font-size: 18px;
	line-height: 1.75;
	/* hyphens: auto; */
	max-width: 30rem;
}

@media (max-width: 500px) {
	.Content {
		width: 100%;
		flex-direction: column;
		height: 70%;
	}

	.Text {
		width: 80vw;
	}

	.Vid {
		width: 200px;
		height: 200px;
	}

	.Co {
		font-size: 1.5em;
	}

	.Welcome {
		font-size: 1em;
	}

	.Sub {
		font-size: 0.75em;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0%;
		transform: translateY(300px);
	}

	100% {
		opacity: 100%;
		transform: translateY(0px);
	}
}

.cclogo{
	width: 60px !important;
	height: 60px !important;
}

.cclogo2{
	margin:auto;
	margin-left: 10px;
	width: 25px !important;
	height: 25px !important;
}

.cclogo3{
	margin:auto;
	margin-left: 10px;
	width: 45px !important;
	height: 45px !important;
}
.ptbologo{
	width: 70px;
	height: 60px;
}
.apilogo{
	width: 90px;
	height: 60px;
}
.inLine{
	display: flex;
	display: table-column
}
.APIWrapper {
	justify-content: center;
	margin-top: 20px;
	margin-left: 5px;
	margin-right: 5px;
	overflow-y: scroll !important;
	margin-bottom: 50px;
	border: 2px solid #00531c;
	border-radius: 7px;
	color: white;
	justify-content: center !important;
	align-items: center !important;
	
    width: 100%;
}
@font-face {
	font-family: 'Blanka-Regular';
	src: url('./Blanka-Regular.ttf');
  }

.words{
	font-family: 'Blanka-Regular';
	font-size: 1em;
	color: white !important;
}

.words8{
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
	font-size: 5em;
	color: black !important;
}





.words7{
	font-family: 'Blanka-Regular';
	font-size: 1.5em;
	color: black !important;
}

.head{
	margin: auto;
	justify-content: center;
	display: flex !important;
	align-items: center !important;
}

.head3{
	margin: auto;
	
	justify-content: center;
	display: flex !important;
	align-items: center !important;
}

.head2{
	margin: auto;
	justify-content: center;
	display: flex !important;
	align-items: center !important;
	font-size: 2em;
}

.words2{
	font-family: 'Courier New', Courier, monospace !important;
	font-size: 1.1em !important;
	color: white !important;
	margin-left: 50px !important;
}
.words10{
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
	color: black !important;
	font-size: 1em !important;
}

.at{
	color: rgb(109, 92, 159)
}
.words3{
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
	color: white !important;
	font-size: 1em !important;
}

.API {
    width: 100%;
	overflow: "auto";
}

h2{
    color:white;
}
h3{
    color: white;
}

.regWrap{
    margin-top: 100px;
    width: 100%;
    color:white;
}

.code {
	font-size: 16px;
	color: rgb(32, 10, 32);
	font-weight: 500;
	background-color: rgb(243, 243, 243);
	border-radius: 5px;
	margin: 5px;
	padding: 5px 0px 5px 5px;
}

.wrap{
	overflow-y: auto !important;
}
.productContainer{
	padding: 20px 20px 20px 20px;
	height: 90vh;
}
.HR {
	height: 3px;
	background-color: var(--n_purple);
	border: none;
	width: 15%;
	margin: 10px 0 0;
}

.Header {
	color: var(--dark-gray);
	display: flex;
	flex-direction: column;
	align-items: left;
}

.EP {
	color: var(--dark-gray);
}

.Name {
	font-weight: bold;
	margin-bottom: 10px;
	margin-top: 20px;
}

.EndPoint {
	color: var(--dark-gray);
	margin-bottom: 8px;
}

[role=navigation] {
    overflow-y: auto !important
    }


	.AnalysisWrapper {
		position: absolute;
		top: 56px;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		/* color: rgb(80, 80, 80); */
		font-weight: bold;
		background-color: #2e2e2e;
		overflow-y: scroll;
	}
	
	.WrapperThe{
		padding: 50px;
		display: flex;
	
	}
	
	.WrapperThe3{
		display: flex;
		padding: 10px
	
	}
	
	.WrapperThe2{
		padding: 50px;
		display: flex;
	
	}
	
	.tableye1{
		width: 50%;
		float: left;
		margin-right: 200px;
		padding: 20px 20px 20px 20px;
		color: white;
	
	}
	
	.tableye{
		width: 10%;
		float: left;
		padding: 20px 20px 20px 20px;
	
	}
	
	.tableye2{
		width: 50%;
		float: left;
		margin-left: 200px;
		color: white;
		padding: 20px 20px 20px 20px;
	
	}
	

	
	
	

	  .dipo{
		display: flex;
		align-items: baseline;
	  }
	
	
	  .words{
		font-family: "Blanka-Regular";
		font-size: 1em;
		color: rgb(17, 15, 15);
		margin: 10px 10px 10px 20px;
	  }
	
	  .words2{
		font-family: "Blanka-Regular";
		font-size: 1.5em;
		color: rgb(235, 228, 228);
		margin: 10px 10px 10px 20px;
	  }
	
	  .words3{
		font-family: "Blanka-Regular";
		font-size: 1.5em;
		color: rgb(17, 15, 15);
		margin: 10px 10px 10px 20px;
		border-radius: 5px;
	  }
		.the{
		/* font-family: "Blanka-Regular"; */
		font-family: "Louis George Café", sans-serif;
	
		font-size: 1.5em;
		color: white;
		margin: 10px 40px 10px 20px;
	
	  }
	
	  .tits{
		color: white;
	  }
	
	  .tits2{
		color: white;
		padding-left: 50px;
	  }
	  .the2{
		font-family: "Blanka-Regular";
	
		font-size: 1.5em;
		color: white;
		margin: 10px 40px 10px 20px;
	
	  }
	
	  .theSpace{
		margin-top: 200px;
		font-family: "Blanka-Regular";
		font-size: 1.5em;
		color: white;
		margin: 10px 40px 10px 20px;
	
	  }
	
	
	.buttonReward{
		margin-left: 5px;
		width: 50px;
		background-color: #ac8686 !important;
		height:30px;
		color: white;
		border-radius: 5px;
		font-size: 12px !important;
		text-align: left !important;
	}

	.buttonReward2{
		margin-left: 5px;
		width: 76px;
		background-color: #ac8686 !important;
		height:30px;
		color: white;
		font-size: 12px !important;
		border-radius: 5px;
		text-align: left !important;
	}

	.buttonReward3{
		margin-left: 5px !important;
		width: 150px !important;
		background-color: #ac8686 !important;
		height:30px !important;
		border-radius: 5px !important;
		color: white !important;
		font-size: 12px !important;
		text-align: left !important;
	}
	
	
	.numberAlive{
		font-family: "Louis George Café", sans-serif;
		font-size: 1.5em;
		background-color: rgb(207, 228, 210);
		border:rgb(33, 164, 53);
		border-radius: 5px;
		padding-left: 15px;
		padding-right: 15px;
		color: black;
		margin: auto;
		margin-left: 10px;
	}
	
	.lastButtons{
		margin-left: 50px;
		margin-right: 50px;
		font-size: 1.2rem;
		display: inline-block;
		font-weight: 400;
		color: #212529;
		text-align: center;
		vertical-align: middle;
		-webkit-user-select: none;
		user-select: none;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		background-color: rgb(22, 190, 118);
		border-radius: 0.25rem;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	
	.numberAlive2{
		margin-left: 50px;
		margin-right: 50px;
		font-size: 1.5rem;
		display: inline-block;
		font-weight: 400;
		color: #212529;
		text-align: center;
		vertical-align: middle;
		-webkit-user-select: none;
		user-select: none;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		background-color: rgb(22, 190, 118);
	
		border-radius: 0.25rem;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	
	
	.numberAlive3{
		margin-left: 20px;
		margin-right: 20px;
		font-size: 1rem;
		display: inline-block;
		font-weight: 400;
		color: #212529;
		text-align: center;
		vertical-align: middle;
		-webkit-user-select: none;
		user-select: none;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		background-color: rgb(22, 190, 118);
		border-radius: 0.25rem;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	
	.diffs{
		border: #f1e1e1;
		border-radius: 5px;
		font-size: 1.2em;
		padding-left: 15px;
		padding-right: 15px;
		margin: auto;
		padding: auto;
	}
	
	.wordGood{
		font-family: "Louis George Café", sans-serif;
		font-size: 1.5em;
		display: flex;
		flex-direction: row;
		color: white;
		margin: auto;
		margin-left: 15px;
		padding: auto;
	}
	
	
	.buttonReward2{
		size: 3em;
		font-size: 1em;
		/* font-family: "Louis George Café", sans-serif; */
		/* font-family: "Blanka-Regular"; */
	
	}
	
	.smaller-input {
		font-size: 12px;
		padding: 2px 5px;
		border-width: .5px;
		border-radius: .5px;
		padding: 2px;
		margin-top: 2px !important;
		max-width: 10px!important;
	  }
	
	  .cool-form .form-control {
		width: 20px;
		margin-top: 2px !important;
	}
	
	.button {
		color: var(--danger);
	}
	.Buttons {
		display: flex;
		flex-direction: row;
		width: 15%;
		justify-content: space-between;
		align-items: center;
	}
	
	
	.thetitle{
			font-family: "Blanka-Regular";
			font-size: 2.0em;
			color: white;
			margin: 70px 20px 0px 20px;
			display: flex;
	}
	.thetitle3{
		font-family: "Blanka-Regular";
		font-size: 2.0em;
		color: white;
		
		display: flex;
	}
	
	.thetitle2{
		font-family: "Blanka-Regular";
		font-size: 3.0em;
		color: white;
		margin: 70px 20px 45px 20px;
		display: flex;
	}
	
	.tithard{
		font-family: "Blanka-Regular";
		font-size: 3.0em;
		color: black;
		margin: 70px 20px 45px 20px;
		display: flex;
	}
	
	.Chart {
		margin-top: 0px;
		margin-bottom: 80px;
		width: 100%;
		max-width: 1100px;
		background-color: #2e2e2e;
		border-radius: 5px;
		padding: 25px;
	}
	
	.ChartWrapper {
		display: flex;
		flex-direction: row;
		padding: 10px;
		background-color: #1f1e1e;
		border: 8px solid rgb(66, 69, 69);
		border-radius: 7px;
	}
	
	.plus{
		color: black;
	}
	
	.space{
		margin-top: 100px;
	}
	
	.ChartParams {
		display: flex;
		flex-direction: row;
		border: 1px solid rgb(177, 177, 177);
		padding: 25px;
		justify-content: space-around;
		align-items: center;
		border-radius: 5px;
		margin: 20px;
	}
	
	.fiatImg {
		width: 50px;
		height: 50px;
		border-radius: 5px;
		object-fit: contain;
	}
	
	.setToggles {
		border: 12px solid rgb(23, 25, 25);
		border-radius: 7px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		background-color: #f4f4f4;
		align-items: center;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		padding: 25px;
		margin: 20px;
		margin-bottom: 100px;
	}
	
	.setTogglesX {
		border: 8px solid rgb(23, 25, 25);
		border-radius: 7px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		background-color: #141414;
		align-items: center;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		padding: 25px;
		margin: 20px;
		margin-bottom: 100px;
	}
	
	.setTogglesXY {
		border: 8px solid rgb(23, 25, 25);
		border-radius: 7px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		background-color: #141414;
		align-items: center;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		padding: 25px;
		margin: 20px;
		margin-top: 80px;
		margin-bottom: 50px;
	}
	
	.setToggles3 {
		border: .2px solid rgb(20, 20, 20);
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		vertical-align: center;
		justify-content: center;
		margin: auto;
		align-items: vertical;
		background-color: #212529;
		width: 400px;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		/* padding: 25px; */
		/* margin: 20px; */
	
	}

	.setToggles8 {
		border: .2px solid rgb(20, 20, 20);
		border-radius: 3px;
		vertical-align: center;
		margin: auto;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: vertical;
		background-color: #212529;
		width: 700px;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		/* padding: 25px; */
		/* margin: 20px; */
	
	}

	.setToggles9 {
		border: .2px solid rgb(20, 20, 20);
		border-radius: 3px;
		display: flex;
		vertical-align: center;
		margin: auto;
		flex-direction: column;
		justify-content: space-around;
		align-items: vertical;
		background-color: #212529;
		width: 900px;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		/* padding: 25px; */
		/* margin: 20px; */
	
	}
	
	.canvas{
		width:1000px !important;
		height:500px !important;
	}
	.setToggles4 {
		border: .2px solid rgb(122, 76, 76);
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: vertical;
		background-color: #212529;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		/* padding: 25px; */
		/* margin: 20px; */
	
	}
	
	
	
	.setToggles2 {
		border: 8px solid rgb(24, 25, 25);
		border-radius: 7px;
		background-color: #F1E1E1;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		/* border: 1px solid rgb(177, 177, 177);
		border-radius: 5px; */
		padding: 25px;
		margin: 60px 60px 60px 60px;
	}
	
	.logoAnalysis{
	
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			height: 5vw;
			margin-top: 1px;
			margin-left: 10px;
			margin-right: 10px;
	
	}
	
	.incomeStuff{
		align-items: vertical;
	}
	
	.SpinnerWrapper {
		position: absolute;
		top: 0;
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		background-color: #2e2e2e;
	}
	
	.colorForLoad{
		color:white;
	}
	
	.overlayTriggers {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		border: 1px solid rgb(177, 177, 177);
		border-radius: 5px;
		padding: 25px;
		margin: 20px;
	}
	
	.quantGroup {
		border: 3px solid #2b1a1a;
		border-radius: 7px;
		background-color: #2b1a1a;
		/* padding: 10px 10px 10px 15px; */
		/* margin-top: 40px; */
		display: flex;
		flex-direction: row;
		justify-content: center;
		vertical-align: center;
		align-items: center;
		height: 100%;
		/* background-color: #2e2e2e; */
		
	}
	.triggerGroup {
		display: flex;
		align-items: center;
	}
	
	.buttonAndInfo {
		font-family: "Blanka-Regular";
		display: flex;
		flex-direction: row;
		align-items: center;
		color: white;
	}
	
	.helpIcon {
		font-size: 28px !important;
		margin-left: 10px;
		padding: 5px;
		color:rgb(22, 190, 118);
		display: flex;
		transition: all 0.1s ease-in-out;
		font-family: "Louis George Café", sans-serif;
		opacity: .8;
	}
	
	.helpIcon:hover {
		background: rgb(240, 240, 240);
		padding: 5px;
		border-radius: 5px;
	}
	
	.help:before {
		content: attr(tooltip-data);
		position: absolute;
		transform: translate(-40%, -100%);
		width: 200px;
		padding: 10px;
		border-radius: 3px;
		background-color: rgb(116, 116, 116);
		color: #fff;
		text-align: center;
		visibility: hidden;
		font-family: "Louis George Café", sans-serif;
		opacity: 0;
		font-size: 12px;
	}
	
	.help:hover:before {
		visibility: visible;
		opacity: 1;
	}
	
	.header {
		position: relative;
		top: -30px;
		left: 140px;
	}
	
	.depletionSet {
		display: flex;
		height: 100%;
		flex: 0.5;
		flex-direction: row;
		justify-content: space-around;
		margin: auto;
	}
	
	.basisSet {
		display: flex;
		flex: 0.5;
		justify-content: center;
		align-items: center;
	}
	
	.BarContainer {
		display: flex;
		flex: 0.3;
		width: 100%;
		align-items: center;
	}
	
	.Bar {
		display: flex;
		flex-direction: row;
		border: 1px solid rgb(177, 177, 177);
		width: 300px;
		height: 30px;
		border-radius: 5px;
	}
	
	.Realizing {
		background-color: rgba(242, 120, 75, 0.9);
		transition: all 0.3s ease-in-out;
	}
	
	.Realizing:before {
		content: attr(tooltip-data);
		position: absolute;
		transform: translate(-30%, -80%);
		margin-left: 15px;
		width: 100px;
		padding: 5px;
		border-radius: 3px;
		background-color: rgb(116, 116, 116);
		color: #fff;
		text-align: center;
		display: none;
		font-size: 12px;
	}
	
	.Realizing:hover:before {
		display: block;
	}
	
	.Unrealized {
		background-color: rgba(191, 191, 191, 0.9);
		transition: all 0.3s ease-in-out;
	}
	
	.Unrealized:before {
		content: attr(tooltip-data);
		position: absolute;
		transform: translate(-30%, -80%);
		margin-left: 15px;
		width: 100px;
		padding: 5px;
		border-radius: 3px;
		background-color: rgb(116, 116, 116);
		color: #fff;
		text-align: center;
		display: none;
		font-size: 12px;
	}
	
	.Unrealized:hover:before {
		display: block;
	}
	
	.Unrealized:hover {
		background-color: rgba(131, 131, 131, 0.9);
	}
	
	.Realizing:hover {
		background-color: rgba(185, 90, 56, 0.9);
	}
	
	.realizingTooltip {
		visibility: hidden;
		width: 120px;
		background-color: #2e2e2e;
		color: #fff;
		padding: 5px 5px;
		border-radius: 5px;
		position: relative;
		top: -30px;
		z-index: 1;
		padding: 5px;
		border-radius: 3px;
		background-color: rgb(116, 116, 116);
		color: #fff;
		text-align: center;
		font-size: 12px;
	}
	
	
	.Realizing:hover .realizingTooltip {
		visibility: visible;
	}
	
	.Label {
		font-size: 0.9rem;
		color: #2e2e2e;
		opacity: 0.5;
		margin-right: 0.5rem;
	}
	
	.SpinnerText {
		margin-left: 5px;
		color: gray;
	}
	