/* Header styles */

/* Background and element positioning */
.Header {
	position: fixed;
	top: 0;
	z-index: 1;
	height: 70px;
	width: 100vw;
	/* background-color: black; */
	background-color: rgb(248, 248, 248);

	box-shadow: 0 4px 12px rgb(0 0 0 / 8%), 0 0 1px rgb(1 0 0 / 10%);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

/* Logo and Name */
.Logo {
	position: absolute;
	left: 0;
	margin-left: 200px;
	text-decoration: none;
	align-items: center;
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

/* Logo image */
.Logo img {
	height: 50px;
	width: 60px;
	border-radius: 3px;
	margin-right: 10px;
}

/* Name styles */
.Name {
	/* color: white; */
	color: var(--dark-gray);
	font-weight: 500;

	font-size: 1.3rem;
}

/* Nav Link styles */
.NavGroup {
	font-size: 1.3rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: baseline;
	height: 100%;
}

.Link {
	display: flex;
	height: 98%;
	justify-content: center;
	text-decoration: none;
	align-items: center;
	/* color: white; */
	color: var(--n_gray);
	font-size: 1rem;
	padding: 0px 20px;
	border-bottom: transparent solid 2px;
}

.Link:hover {
	cursor: default;
	border-bottom: solid 2px var(--n_purple);
}

.WindowWrapper {
	width: 100vw;
	position: fixed;
	top: 70px;
	z-index: 2;
	height: 80vh;
	display: flex;
	justify-content: center;
}

.BlogWindow {
	width: 40%;
	max-width: 500px;
	height: 70%;
	background-color: rgb(248, 248, 248);
	border-radius: 3px;

	padding: 30px;

	box-shadow: 0 4px 12px rgb(0 0 0 / 8%), 0 0 1px rgb(1 0 0 / 10%);
	animation-name: fadeIn;
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	overflow: hidden;
}

.WindowLink {
	color: var(--dark-gray);
	text-decoration: none;
	width: 10%;
}

.HR {
	opacity: 50%;
	border: 1px solid rgba(219, 219, 219, 0.678);
	margin-bottom: 20px;
}

.Title {
	font-size: 1.1rem;
	color: var(--dark-gray);
	width: fit-content;
	letter-spacing: 0.3;
	margin: 0px;
	border-bottom: 2px solid transparent;
	transition: color 0.3s ease-in-out, border-bottom, 0.1s ease-in-out;
}

.WindowLink:hover .Title {
	color: var(--n_purple);
	border-bottom: 2px solid var(--n_purple);
}

.Description {
	width: 50%;
	font-size: 0.9rem;
	margin-top: 5px;
	margin-bottom: 20px;
	font-weight: 400;
	line-height: 20px;
	color: var(--n_gray);
}

.ContactForm {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 0px;
}
.ContactLink {
	margin-left: 10px;
	font-size: 1.3rem;
	color: var(--dark-gray);
	text-decoration: none;
	width: 10%;
}

.ContactForm:hover .ContactLink {
	color: var(--n_purple);
}

.ContactRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
	margin: 25px 0px;
}

.Icon {
	color: var(--dark-gray);
}

@keyframes fadeIn {
	0% {
		opacity: 0%;
	}
	100% {
		opacity: 100%;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 0%;
	}
	100% {
		opacity: 100%;
	}
}

@media (max-width: 1330px) {


	.Logo {
		margin-left: 15%;
	}

	.NavLink {
		font-size: 0.8rem;
	}
}

@media (max-width: 500px) {
	.Logo img {
		height: 20px;
		width: 20px;
		margin-left: 5px;
	}

	.Logo {
		margin-left: 15px;
		margin-right: 15px;
	}

	.Description {
		width: 90%;
	}

	.BlogWindow {
		width: 100%;
	}
}
