.Updates {
	margin: 25px 0px;
	color: black;
}

.Notes {
	color: var(--n_gray);
	font-size: 0.8em;
	font-weight: 400;
}

.Samples {
	display: flex;
	flex-direction: column;
	color: var(--n_gray);
}

.Samples img {
	border-radius: 3px;
	margin: 5px;
	width: 100%;
}
