.APIWrapper {
	margin-top: 60px;
	overflow-y: scroll !important;
	margin-left: 60px;
	width: auto;
	white-space:nowrap;
	height: 3000px !important;
}

.wrap{
	overflow-y: scroll !important;
	height: 3000px !important;
	box-sizing: padding-box;
    -moz-box-sizing: padding-box;
    -webkit-box-sizing: padding-box;
    padding-bottom: 100px;
}


.API {
	width: 80%;
	overflow-y: scroll !important;
}

.code {
	font-size: 16px;
	color: rgb(32, 10, 32);
	font-weight: 500;
	background-color: rgb(243, 243, 243);
	border-radius: 5px;
	margin: 5px;
	padding: 5px 0px 5px 5px;
}

.HR {
	height: 3px;
	background-color: var(--n_purple);
	border: none;
	width: 15%;
	margin: 10px 0 0;

}

.Header {
	color: var(--dark-gray);
	display: flex;
	flex-direction: column;
	align-items: left;
	margin-top: 50px
}

.EP {
	color: var(--dark-gray);
	font-size: .85em;
}

.Name {
	font-weight: bold;
	margin-bottom: 10px;
	margin-top: 20px;
}

.EndPoint {
	color: var(--dark-gray);
	margin-bottom: 8px;
}
